<script>
import moment from 'moment';
import AddSalary from '@/pages/employees/employees/popups/PopupAddSalary.vue';
import EditSalary from '@/pages/employees/employees/popups/PopupEditSalary.vue';
import HistorySalary from '@/pages/employees/employees/popups/PopupSalaryInfoHistory.vue';
import { ValidateUtil } from '@/plugins/app-util';

export default {
    components: {
        AddSalary,
        EditSalary,
        HistorySalary,
    },
    props: {
        employeeData: Object,
    },
    data() {
        return {
            employeeSeq: null,
            salaryInfo: null,
            salaryInfoList: null,
            addOrEditPopup: false,
            editPopup: false,
            showHistoryPopup: false,
            showSalaryEditBtn: true,
            employeeSalary: {
                tinNo: '',
                bankName: '',
                bankAccountNumber: '',
                pagIbig: '',
                socialSecuritySystem: '',
                philhealth: '',
                rdoCode: '',
            },
            salaryType: { ANNUALLY: 'Annually', MONTHLY: 'Monthly', WEEKLY: 'Weekly' },
        }; //return
    }, //data
    methods: {
        moment: moment,
        parseInt: parseInt,
        over15Charaters: ValidateUtil.over15Charaters,
        getEmployeeSalaryInfo() {
            const self = this;

            if (!self.employeeSeq) {
                return;
            }

            const url = self.$api('uri', 'get-employee-salary-info').replace('{employeeSeq}', self.employeeSeq);

            self.$axios
                .get(url)
                .then((res) => {
                    self.salaryInfo = res.data.data.employeeSalaryInfo;
                    self.salaryInfoList = res.data.data.employeeSalaryInfoList;
                    if (self.salaryInfo) {
                        self.showSalaryEditBtn = false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        addOrEditSaveButtonClick() {
            const self = this;

            const salaryData = self.$refs.addSalary.getSalaryData();

            if (!self.validationSalary(salaryData)) {
                return;
            }

            const employeeSeq = self.employeeSeq;
            const requestItem = { ...salaryData };

            const url = self.$api('uri', 'post-employee-salary-info').replace('{employeeSeq}', employeeSeq);
            self.$axios
                .post(url, requestItem)
                .then((res) => {
                    self.addOrEditPopup = false;
                    self.getEmployeeSalaryInfo();
                    self.setEmployeeSalary(salaryData);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        validationSalary(salaryData) {
            if (!salaryData) {
                return false;
            }

            let salaryAmountType = salaryData.salaryAmountType;
            let salaryAmount = salaryData.salaryAmount;
            let taxCode = salaryData.taxCode;
            let effectiveDate = salaryData.effectiveDate;
            let pic = salaryData.pic;

            if (salaryAmountType == null || salaryAmountType === '') {
                alert('Please select a salary type.');
                return false;
            }

            if (salaryAmount == null || salaryAmount === '' || Number.isNaN(salaryAmount)) {
                alert('Please enter a number in amount.');
                return false;
            }

            if (salaryAmount < 0) {
                alert('The salary amount must be a positive number.');
                return false;
            }

            if (taxCode == null || taxCode === '') {
                alert('Please select a tax code.');
                return false;
            }

            if (effectiveDate == null) {
                alert('Please select a effective Date.');
                return false;
            }

            if (effectiveDate < salaryData.minEffectiveDate) {
                alert('Please set the effective date last payroll cut off or join date');
                return false;
            }

            const self = this;
            if (self.salaryInfoList != null && self.salaryInfoList.length > 0) {
                for (let salaryInformation of self.salaryInfoList) {
                    let tempEffectiveDate = moment(salaryInformation.effectiveDate).format('YYYYMMDD');
                    if (effectiveDate === tempEffectiveDate) {
                        alert('An effective date with the same date already exists.');
                        return false;
                    }
                }
            }

            if (pic == null || pic === '') {
                alert('Please input the name of PIC');
                return false;
            }

            // 비 필수값 확인
            let peraYear = salaryData.peraYear;
            let peraAmount = salaryData.peraAmount;
            if (peraYear != null) {
                if (Number.isNaN(peraYear)) {
                    alert('Please enter a number in pera year.');
                    return false;
                }

                if (peraYear < 0) {
                    alert('The Pera year must be positive.');
                    return false;
                }
            }

            if (peraAmount != null) {
                if (Number.isNaN(peraAmount)) {
                    alert('Please enter a number in pera amount.');
                    return false;
                }

                if (peraAmount < 0) {
                    alert('The Pera amount must be positive.');
                    return false;
                }
            }

            return true;
        },
        editSaveButtonClick() {
            const self = this;

            const salaryData = self.$refs.editSalary.getSalaryData();

            // edit salary 는 sss 같은 정보를 입력하는 것임
            // 이름을 바꿔야 하는데 지금 브랜치에 혼용 되고 있어서 추후 이름 변경 해야함
            // if(!self.validationSalary(salaryData)) {
            //     return;
            // }

            const employeeSeq = self.employeeSeq;
            const requestItem = { ...salaryData };

            const url = self.$api('uri', 'put-employee-salary').replace('{employeeSeq}', employeeSeq);
            self.$axios
                .put(url, requestItem)
                .then((res) => {
                    self.editPopup = false;
                    self.setEmployeeSalary(salaryData);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        setEmployeeSalary(salaryData) {
            const self = this;
            self.employeeSalary = salaryData;
        },
        addOrEditButton() {
            const self = this;
            self.addOrEditPopup = true;
        },
        editButton() {
            const self = this;
            self.editPopup = true;
        },
        numberWithCommas(number) {
            if (!number) {
                return 0.0;
            }
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        clickHistory() {
            const self = this;
            self.showHistoryPopup = true;
        },
        getTinNumberFormat(input) {
            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                formattedValue = inputValue.match(new RegExp('.{1,3}', 'g')).join('-'); // xxx-xxx-xxx
                const regex = /^(\d{3})(\d{3})(\d{3})(\d{1,})$/;
                const match = regex.exec(inputValue);

                if (match) {
                    formattedValue = `${match[1]}-${match[2]}-${match[3]}-${match[4]}`; // xxx-xxx-xxxxx
                }
            }

            return formattedValue;
        },
        getSSSNumberFormat(input) {
            if (!input) {
                return;
            }

            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                const patternIndexArr = [2, 7];
                let patternValueArr = [];

                let value = inputValue;
                for (const pIndex of patternIndexArr) {
                    if (value.length > pIndex) {
                        let cut = value.substr(0, pIndex);
                        value = value.substr(pIndex);
                        patternValueArr.push(cut);
                    } else {
                        break;
                    }
                }

                if (value) {
                    patternValueArr.push(value);
                }

                formattedValue = patternValueArr.join('-');
            }

            return formattedValue;
        },
        getPhilhealthFormat(input) {
            if (!input) {
                return;
            }

            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                const patternIndexArr = [2, 9];
                let patternValueArr = [];

                let value = inputValue;
                for (const pIndex of patternIndexArr) {
                    if (value.length > pIndex) {
                        let cut = value.substr(0, pIndex);
                        value = value.substr(pIndex);
                        patternValueArr.push(cut);
                    } else {
                        break;
                    }
                }

                if (value) {
                    patternValueArr.push(value);
                }

                formattedValue = patternValueArr.join('-');
            }

            return formattedValue;
        },
        getPagIbigFormat(input) {
            if (!input) {
                return;
            }

            const inputValue = input?.replace(/\D/g, ''); // Remove non-digit characters
            let formattedValue = '';

            if (inputValue) {
                //
                const patternIndexArr = [4, 4];
                let patternValueArr = [];

                let value = inputValue;
                for (const pIndex of patternIndexArr) {
                    if (value.length > pIndex) {
                        let cut = value.substr(0, pIndex);
                        value = value.substr(pIndex);
                        patternValueArr.push(cut);
                    } else {
                        break;
                    }
                }

                if (value) {
                    patternValueArr.push(value);
                }

                formattedValue = patternValueArr.join('-');
            }

            return formattedValue;
        },
    },
    mounted() {
        const self = this;

        if (self.employeeData) {
            self.employeeSeq = self.employeeData.employeeSeq;
            self.getEmployeeSalaryInfo();
            self.setEmployeeSalary(self.employeeData);
        }
    },
    computed: {},
};
</script>

<template>
    <div class="contents_title flex justify-between mb-7">
        <p class="text-[22px] text-[#2E2C34] font-bold">Salary Information</p>
    </div>
    <div class="contents_detail">
        <div class="flex justify-between items-center mt-6 h-10">
            <h4 class="text-lg font-bold">Payroll Info</h4>
        </div>
        <div class="w-full flex justify-between mb-3">
            <p class="text-[#504F54] text-sm font-bold"></p>
            <a href="javascript:void(0)" @click="editButton">
                <p class="text-[#4640DE] text-sm font-bold">Edit</p>
            </a>
        </div>
        <div class="border rounded-lg border-color-gray-200">
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-bold w-3/12">Tin Number</h5>
                <p class="font-medium text-gray-400 w-9/12">
                    {{ getTinNumberFormat(employeeSalary.tinNo) }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-bold w-3/12">SSS No.</h5>
                <p class="font-medium text-gray-400 w-9/12">
                    {{ getSSSNumberFormat(employeeSalary.socialSecuritySystem) }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-bold w-3/12">PHIL-HEALTH No.</h5>
                <p class="font-medium text-gray-400 w-9/12">
                    {{ getPhilhealthFormat(employeeSalary.philhealth) }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-bold w-3/12">PAG-IBIG No.</h5>
                <p class="font-medium text-gray-400 w-9/12">
                    {{ getPagIbigFormat(employeeSalary.pagIbig) }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-bold w-3/12">RDO Code</h5>
                <p class="font-medium text-gray-400 w-9/12">
                    {{ employeeSalary.rdoCode }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-bold w-3/12">Bank Name</h5>
                <p class="font-medium text-gray-400 w-9/12">
                    {{ employeeSalary.bankName }}
                </p>
            </div>
            <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                <h5 class="font-bold w-3/12">Bank Account Number</h5>
                <p class="font-medium text-gray-400 w-9/12">
                    {{ employeeSalary.bankAccountNumber }}
                </p>
            </div>
        </div>
    </div>
    <div class="contents_detail">
        <div class="flex justify-between items-center mt-6 h-10">
            <h4 class="text-lg font-bold">Salary Details</h4>
            <button v-show="showSalaryEditBtn" @click="addOrEditButton" class="popup-button rounded border border-[#4640DE] px-[22px] py-[10px] text-[#4640DE] text-base font-medium">Add</button>
        </div>
        <div v-if="salaryInfo" class="justify-center items-end">
            <div class="w-full flex justify-between mb-3">
                <p class="text-[#504F54] text-sm font-bold"></p>
                <a href="javascript:void(0)" @click="addOrEditButton">
                    <p class="text-[#4640DE] text-sm font-bold">Update Salary</p>
                </a>
            </div>
            <div class="border rounded-lg border-color-gray-200">
                <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                    <h5 class="font-bold w-3/12">PIC</h5>
                    <p class="font-medium text-gray-400 w-9/12">
                        {{ salaryInfo.pic }}
                    </p>
                </div>
                <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                    <h5 class="font-bold w-3/12">Join Date</h5>
                    <p class="font-medium text-gray-400 w-9/12">
                        {{ moment(parseInt(employeeData.joinDate), 'YYYYMMDD').format('MM/DD/YYYY') }}
                    </p>
                </div>
                <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                    <h5 class="font-bold w-3/12">Effective Date</h5>
                    <p class="font-medium text-gray-400 w-9/12">
                        {{ moment(salaryInfo.effectiveDate).format('MM/DD/YYYY') }}
                    </p>
                </div>
                <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                    <h5 class="font-bold w-3/12">Employment Type</h5>
                    <p class="font-medium text-gray-400 w-9/12">Regular</p>
                </div>
                <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                    <h5 class="font-bold w-3/12">Amount</h5>
                    <p class="font-medium text-gray-400 w-9/12">
                        {{ numberWithCommas(salaryInfo.salaryAmount) }}
                        <span> {{ `(${salaryType[salaryInfo.salaryAmountType]})` }} </span>
                    </p>
                </div>
                <div class="border-b border-color-gray-200 h-fit flex items-center text-sm px-4">
                    <h5 class="font-bold w-3/12">Tax Code</h5>
                    <div class="font-medium text-gray-400 w-9/12 py-2">
                        {{ salaryInfo.taxCode }}
                        <br />
                        Certificate File :
                        <template v-if="salaryInfo.taxFilePath">
                            <a :href="salaryInfo.taxFilePath">
                                <span class="text-blue-500 whitespace-pre-line">
                                    {{ over15Charaters(salaryInfo.taxFileName) }}
                                </span>
                            </a>
                        </template>
                        <template v-else>
                            <span>-</span>
                        </template>
                    </div>
                </div>

                <div class="border-b border-color-gray-200 h-fit flex items-center text-sm px-4">
                    <h5 class="font-bold w-3/12">PERA Info</h5>
                    <div class="font-medium text-gray-400 w-9/12 py-2">
                        {{ salaryInfo.peraYear }}/{{ salaryInfo.peraType }}/{{ salaryInfo.peraAmount }} <br />
                        Certificate File :
                        <template v-if="salaryInfo.peraCertificateFilePath">
                            <a :href="salaryInfo.peraCertificateFilePath">
                                <span class="text-blue-500">{{ over15Charaters(salaryInfo.peraCertificateFileName) }} </span>
                            </a>
                        </template>
                        <template v-else>
                            <span>-</span>
                        </template>
                    </div>
                </div>

                <div class="border-b border-color-gray-200 h-14 flex items-center text-sm px-4">
                    <h5 class="font-bold w-3/12">Other Details</h5>
                    <p class="font-medium text-gray-400 w-9/12">
                        {{ salaryInfo.otherDetails }}
                    </p>
                </div>
            </div>
            <div class="pt-3 flex justify-end">
                <button @click="clickHistory()" class="popup-button rounded border border-[#4640DE] px-[22px] py-[10px] text-[#4640DE] text-base font-medium flex items-center">
                    <p class="mr-3">Salary History</p>
                    <img src="@/assets/img/chevron_right_purple.svg" alt="" />
                </button>
            </div>
        </div>
        <div v-else class="flex flex-col justify-center items-center pt-20">
            <img class="mb-4" src="@/assets/img/none.svg" alt="" />
            <p class="text-sm text-[#85878B] font-medium">Please Register Payroll</p>
        </div>
    </div>
    <Teleport to="body">
        <AppPopup v-model="editPopup" :name="'Edit Salary'" button-text="Save" @button-event="editSaveButtonClick" :width800="true">
            <EditSalary :employeeData="employeeData" ref="editSalary" />
        </AppPopup>
        <AppPopup v-model="addOrEditPopup" :name="salaryInfo == null ? 'Add Salary' : 'Update Salary Info'" button-text="Save" @button-event="addOrEditSaveButtonClick">
            <AddSalary v-model="addOrEditPopup" :salaryInfo="salaryInfo" :employeeData="employeeData" ref="addSalary" />
        </AppPopup>
        <AppPopup v-model="showHistoryPopup" :name="'Salary History'">
            <HistorySalary v-model="showHistoryPopup" :employeeSeq="employeeSeq" />
        </AppPopup>
    </Teleport>
</template>
