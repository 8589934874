const employeeType =  [
// full time 지운 데이터 / 만약 full time 이 있는 데이터를 원하시면 contractType 이용 부탁합니다
    {"text": "Regular", "value" : "FULL_TIME" },
    {"text": "Probationary", "value" : "PROBATION"},
    {"text": "Contractual", "value" : "FIXED_TERM_CONTRACT" },
    {"text": "Project Based", "value" : "PROJECT_BASE"},
    {"text": "Outsourced", "value" : "OUTSOURCE" },
    {"text": "Intern/Part-Time", "value" : "TEMPORARY" },
    {"text": "Etc", "value" : "ETC" },
]

const employeeTypeForSpecialLeave =  [
// full time 지운 데이터 / 만약 full time 이 있는 데이터를 원하시면 contractType 이용 부탁합니다
    {"text": "Probationary", "value" : "PROBATION"},
    {"text": "Contractual", "value" : "FIXED_TERM_CONTRACT" },
    {"text": "Project Based", "value" : "PROJECT_BASE"},
    {"text": "Outsourced", "value" : "OUTSOURCE" },
    {"text": "Intern/Part-Time", "value" : "TEMPORARY" },
    {"text": "Etc", "value" : "ETC" },
]
    
const status = [
    {"text" : "Active" , "value" : "ACTIVE" },
    {"text" : "On Leave", "value" : "ON_LEAVE" },
    {"text" : "Resigned", "value" : "RESIGNED" }
]

const leaveTypes = [
    {"text" : "Leave of absence" , "value" : "LEAVE_OF_ABSENCE" },
    {"text" : "Maternity Leave", "value" : "MATERNITY_LEAVE" },
]

const gender = [
    {
        "text": "Male",
        "value": "M"
    },
    {
        "text": "Female",
        "value": "F"
    }
]

const maritalStatus =[
    {
        "text" : "Married",
        "value" : "M"
    },
    {
        "text" : "Single",
        "value" : "S"
    }
]

const graduationCategory =[
    {
        "text" : "Graduated",
        "value" : "Graduated"
    },
    {
        "text" : "Attending", 
        "value" : "Attending"
    },
    {
        "text" : "Leave of absence",
        "value" : "Leave of absence"
    },
    {
        "text" : "Expelled",
        "value" : "Expelled"
    },
    {
        "text" : "Suspended", 
        "value" : "Suspended"
    }
]

const educationTypes = [
    {"text" : "College / Univ / Graduate School / Others" , "value" : "College / Univ / Graduate School / Others" }, 
    {"text" : "High School", "value" : "High School" }, 
    {"text" : "Middle School", "value" : "Middle School" }, 
    {"text" : "Elementary", "value" : "Elementary" }
]

const contractType =[
    {"text": "Regular", "value" : "FULL_TIME" },
    {"text": "Probationary", "value" : "PROBATION"},
    {"text": "Contractual", "value" : "FIXED_TERM_CONTRACT" },
    {"text": "Project Based", "value" : "PROJECT_BASE"},
    {"text": "Outsourced", "value" : "OUTSOURCE" },
    {"text": "Intern/Part-Time", "value" : "TEMPORARY" },
    {"text": "Etc", "value" : "ETC" },
]

export {
    employeeType,
    employeeTypeForSpecialLeave,
    status,
    gender,
    maritalStatus,
    graduationCategory,
    contractType,
    leaveTypes,
    educationTypes
}
