
<template>
    <div class="w-[644px] px-7 pb-7">
        <template v-for="(item, index) in salaryHistoryInfo" :key="index">
            <div class="w-full flex justify-between items-center my-4">
                <p class="text-[#504F54] text-sm font-bold whitespace-nowrap mr-3">Reg Date : <span> {{ dateFormatter( item.salaryRegDatetime , "YYYY-MM-DD HH:mm")}}</span></p>
                <div class="w-full border border-[#000000]"></div>
            </div>
            <div class="border rounded-lg w-full">
                <table class="w-full">
                    <tr>
                        <td class="w-3/12 py-5 px-6 text-[#504F54] text-sm font-bold">PIC</td>
                        <td class="w-9/12 py-5 px-6 text-[#7C8493] text-sm font-medium">{{ item.pic }}</td>
                    </tr>
                    <tr>
                        <td class="w-3/12 py-5 px-6 text-[#504F54] text-sm font-bold"> Effective Date </td>
                        <td class="w-9/12 py-5 px-6 text-[#7C8493] text-sm font-medium"> {{ dateFormatter( item.effectiveDate)}}</td>
                    </tr>
                    <tr>
                        <td class="w-3/12 py-5 px-6 text-[#504F54] text-sm font-bold"> taxCode </td>
                        <td class="w-9/12 py-5 px-6 text-[#7C8493] text-sm font-medium">
                            {{ item.taxCode }}
                            <br/> Certificate File :
                            <template  v-if="item.taxFilePath">
                                <a :href="item.taxFilePath">
                                    <span class=" text-blue-500 whitespace-pre-line">
                                        {{ over15Charaters(item.taxFileName) }}
                                    </span>
                                </a>
                            </template>
                            <template v-else>
                                <span>-</span>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-3/12 py-5 px-6 text-[#504F54] text-sm font-bold">Basic Salary</td>
                        <td class="w-9/12 py-5 px-6 text-[#7C8493] text-sm font-medium">
                            {{ decimalFormat(item.salaryAmount) }}
                            <span> {{ `(${salaryType[item.salaryAmountType]})` }} </span>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-3/12 py-5 px-6 text-[#504F54] text-sm font-bold">PERA Info</td>
                        <td class="w-9/12 py-5 px-6 text-[#7C8493] text-sm font-medium">
                            {{item.peraYear}}/{{item.peraType}}/{{item.peraAmount}}
                            <br/> Certificate File :
                            <template v-if="item.peraCertificateFilePath">
                                <a :href="item.peraCertificateFilePath">
                                    <span class=" text-blue-500 whitespace-pre-line">
                                        {{ over15Charaters(item.peraCertificateFileName) }}
                                    </span>
                                </a>
                            </template>
                            <template v-else>
                                <span>-</span>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-3/12 py-5 px-6 text-[#504F54] text-sm font-bold">Other Details</td>
                        <td class="w-9/12 py-5 px-6 text-[#7C8493] text-sm font-medium">{{item.otherDetails}}</td>
                    </tr>
                </table>
            </div>
        </template>
    </div>
</template>
<script>
import moment from 'moment';
import {ValidateUtil} from "@/plugins/app-util";

export default {
    props : {
        modelValue : Boolean,
        employeeSeq : String,
    },
    watch : {
        modelValue( isOpen ){
            if( ! isOpen ){
                return;
            }

            const self = this;
            self.getSalaryHistoryInfo();
        }
    },
    data(){
        return{
            salaryHistoryInfo : [],
            salaryType : {  ANNUALLY :  "Annually",
                            MONTHLY  :  "Monthly",
                            WEEKLY   :  "Weekly" }
        }
    },
    methods : {
        over15Charaters : ValidateUtil.over15Charaters,
        getSalaryHistoryInfo(){
            const self = this;

            const url = self.$api("uri", "get-employee-salary-list")
                .replace("{employeeSeq}", self.employeeSeq);

            self.$axios.get(url)
                .then((res) => {
                    self.salaryHistoryInfo = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        dateFormatter( date, dateFormat = "YYYY-MM-DD"  ){
            if( ! date ){
                return;
            }
			return moment(date).format(dateFormat)
		},
        decimalFormat( decimalNumber){
            decimalNumber = decimalNumber || 0;
            const options = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            };

            return parseFloat(decimalNumber).toLocaleString('en-US', options);
        },
    }
}
</script>
<style scoped>
    table tr td {
    border-bottom: 1px solid #EAEDF7;
    }

    table tr:nth-last-child(1) td {
    border-bottom: none;
    }
</style>