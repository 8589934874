const LOAN_STATUS = Object.freeze({
    ENABLE : "ENABLE",
    DISABLE : "DISABLE"
});

const LOAN_TYPE = Object.freeze({
    HDMF_CALAMITY_LOAN : "HDMF_CALAMITY_LOAN",
    HDMF_HOUSE_LOAN : "HDMF_HOUSE_LOAN",
    HDMF_SALARY_LOAN :"HDMF_SALARY_LOAN",
    HDMF_SALARY_LOAN_DELINQUENT : "HDMF_SALARY_LOAN_DELINQUENT",
    SSS_CALAMITY_LOAN : "SSS_CALAMITY_LOAN",
    SSS_EMERGENCY_LOAN : "SSS_EMERGENCY_LOAN",
    SSS_HOUSING_LOAN : "SSS_HOUSING_LOAN",
    SSS_SALARY_LOAN : "SSS_SALARY_LOAN",
    SSS_SALARY_LOAN_CONDONATION : "SSS_SALARY_LOAN_CONDONATION",
    SSS_SALARY_LOAN_SLERP : "SSS_SALARY_LOAN_SLERP",
    SSS_SALARY_LOAN_SLERP3 : "SSS_SALARY_LOAN_SLERP3",
    COMPANY_LOAN : "COMPANY_LOAN"
});

const PAYROLL_PAYMENT_ON = Object.freeze({
    FIRST : "1ST",
    SECOND : "2ND",
    THIRD : "3RD",
    FOURTH : "4TH",
    NONE : "NONE"
});

const PAYROLL_FREQUENCY = Object.freeze({
    MONTHLY : "MONTHLY",
    SEMI_MONTHLY : "SEMI_MONTHLY",
    WEEKLY : "WEEKLY",
    ONLY_THIRTEEN_MONTH_PAY : "ONLY_THIRTEEN_MONTH_PAY"
});

const PAYROLL_FREQUENCY_DISPLAY_NAME = Object.freeze({
    ANNUALLY : "Annually",
    MONTHLY : "Monthly",
    SEMI_MONTHLY : "Semi-Monthly",
    WEEKLY : "Weekly"
});

export {
    LOAN_STATUS,
    LOAN_TYPE,
    PAYROLL_PAYMENT_ON,
    PAYROLL_FREQUENCY,
    PAYROLL_FREQUENCY_DISPLAY_NAME
};