<template>
    <div>
        <ElementsTooltipSingleton
            :ref="
                (el) => {
                    loadTooltipElementReady(el);
                }
            " />
        <div class="flex gap-2 mt-[25px] font-bold text-[18px] mb-[20px]">
            Approval process
            <InformationCircleIcon
                class="h-6 w-6 text-blue-600"
                aria-hidden="true"
                :ref="
                    (el) => {
                        loadTooltip(el, () => {
                            return 'Level of approver is Maximum of 5.\
                    <br/>You can set up a level head or a specific person as an approver.';
                        });
                    }
                " />
        </div>
        <div class="process-content w-full">
            <template v-for="(approveItem, index) in approvedList" :key="index">
                <div class="flex">
                    <div class="w-full border-gray-300 bg-white border-[1px] rounded-[8px] mb-3 pt-[12px] px-[12px] pb-3">
                        <div class="flex items-center justify-between">
                            <p class="text-[16px]">Level {{ index + 1 }}</p>
                            <ElementsToggle v-model="approveItem.autoApprovedYn" :toggleLabel="'automatic approval'" :useStringValueObj="toggleYesOrNoOptions" :disabled="disabled" />
                        </div>
                        <div class="flex items-center justify-between py-[7px] px-[17px] border-gray-300 bg-white border-[1px] rounded-[8px] mt-[26px]">
                            <div v-if="approveItem.approverTarget.approveType == 'USER'">{{ approveItem.approverTarget.employeeName }}</div>
                            <div v-else-if="approveItem.approverTarget.approveType == 'FIRST_HEAD'">Level 1 Head</div>
                            <div v-else-if="approveItem.approverTarget.approveType == 'SECOND_HEAD'">Level 2 Head</div>
                            <div v-else-if="approveItem.approverTarget.approveType == 'THIRD_HEAD'">Level 3 Head</div>
                            <div v-else></div>
                            <ElementsButton class="ml-3" text="Select" v-show="!disabled" :bgWhiteAndtextIndigo="true" :width20="true" :height12="true" @click="openSelectApprovalPopup(index)" />
                        </div>
                        <div class="flex items-center justify-between mt-2">
                            <div>
                                <div class="text-xs font-normal text-red-500" v-show="approveItem.error">* you need to add employee or head of organization as approval</div>
                            </div>
                            <ElementsButton class="ml-2" text="Delete" :backgroundRed="true" :width20="true" :height12="true" v-show="!disabled" @click="deleteProcessStep(index)" />
                        </div>
                    </div>
                    <!-- <div class="pl-2 text-red-500 font-semibold" @click="deleteProcessStep(index)"> X </div> -->
                </div>
            </template>
            <button v-if="!disabled" v-show="approvedList.length < 5" class="border-gray-300 bg-white border-[1px] rounded-[8px] mt-[15px] h-[52px] w-full" @click="addApproveStep">
                + Add a Level
            </button>
        </div>
        <Teleport to="body">
            <AppPopup v-model="showMemberAssignment" name="Select an Approver">
                <div>
                    <div class="member-assign-popup w-[644px] py-[28px] px-[27px]">
                        <div class="flex">
                            <ElementsSelectRef
                                v-model="selectedDepartment"
                                :options="departmentsList"
                                :width40="true"
                                :useDefaultMsg="true"
                                :placeholder="'ALL'"
                                class="mr-[16px] rounded w-[161px]"
                                @change="getEmployeeListBySearch" />
                            <ElementsSearchBar
                                v-model="nameForSearch"
                                :options="employeeOptions"
                                :width72="true"
                                :height11="true"
                                class="mt-1"
                                :placeholder="'Search name'"
                                @searchEvent="getEmployeeListBySearch"
                                ref="employeeSearchBar"
                                :optionClickEvent="true" />
                            <button class="ml-4 mt-1 border rounded blu text-sm font-semibold text-white transition-all duration-500 text-center px-5 w-fit h-11" @click="getEmployeeListBySearch">
                                Search
                            </button>
                        </div>
                        <div class="employees_container mt-[15px] border-gray-300 border-[1px] rounded-[10px]">
                            <div class="title text-gray-500 font-semibold text-[14px] border-gray-300 border-b-[1px] px-[28px] pt-[20px] pb-[24px]">Employees</div>
                            <ul>
                                <li class="cursor-pointer py-[20px] px-[31px] border-gray-300 border-b-[1px]" @click="selectApprover('FIRST_HEAD', null, 'Level 1 Head')">
                                    <div class="flex gap-2 items-center">
                                        <div class="rec text-[14px] flex items-center justify-center bg-[#FF7777] w-[200px] h-[30px] rounded-[4px] text-white">Level 1 Head</div>
                                        <InformationCircleIcon
                                            class="h-6 w-6 text-blue-600"
                                            aria-hidden="true"
                                            :ref="
                                                (el) => {
                                                    loadTooltip(el, () => {
                                                        return 'The Level refers to the department level of the organization settings.';
                                                    });
                                                }
                                            " />
                                    </div>
                                </li>
                                <li class="cursor-pointer py-[20px] px-[31px] border-gray-300 border-b-[1px]" @click="selectApprover('SECOND_HEAD', null, 'Level 2 Head')">
                                    <div class="rec text-[14px] flex items-center justify-center bg-[#428ADF] w-[200px] h-[30px] rounded-[4px] text-white">Level 2 Head</div>
                                </li>
                                <li class="cursor-pointer py-[20px] px-[31px] border-gray-300" @click="selectApprover('THIRD_HEAD', null, 'Level 3 Head')">
                                    <div class="rec text-[14px] flex items-center justify-center bg-[#41C02C] w-[200px] h-[30px] rounded-[4px] text-white">Level 3 Head</div>
                                </li>
                                <template v-for="(employee, index) in employeeLists" :key="employee.employeeSeq">
                                    <li class="flex items-center pl-4 border-gray-300 border-t-[1px]" :class="{ 'bg-slate-50': index % 2 !== 0 }">
                                        <button
                                            @click="selectApprover('USER', employee.employeeSeq, employee.employeeName)"
                                            class="group p-2 w-full flex items-center justify-between space-x-3 text-left">
                                            <div class="grid grid-cols-10 gap-x-4 gap-y-2 items-center">
                                                <StatusCircle :status-code="employee.employeeStatus" class="col-span-1"/>
                                                <div class="text-base font-bold col-span-9"> {{ employee.employeeName }} </div>
                                                <div class="col-span-1"></div>
                                                <div class="col-span-9 text-sm font-normal">{{ employee.departmentName || ""}} </div>
                                            </div>
                                        </button>
                                    </li>
                                </template>
                                <li class="py-[20px] px-[31px] border-gray-300 border-t-[1px]">
                                    <div class="text-xs font-normal text-gray-500 text-center">Maximum 10 employees are displayed</div>
                                    <div class="text-xs font-normal text-gray-500 text-center">If you want more, try to search again with more specific keywords.</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </AppPopup>
        </Teleport>
    </div>
</template>

<script>
import ElementsToggle from '@/components/elements/ElementsToggle.vue';
import { ValidateUtil } from '@/plugins/app-util.js';
import { InformationCircleIcon } from '@heroicons/vue/outline';
import StatusCircle from '@/components/employee/status-circle/status-circle';

export default {
    components: {
        ElementsToggle,
        InformationCircleIcon, StatusCircle
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        const self = this;
        self.getDepartmentOptions();
        self.getEmployeeOptions();
    },
    data() {
        return {
            departmentsList: [],
            employeeOptions: [],
            departmentNameBySeq: {},
            showMemberAssignment: false,
            selectedDepartment: null,
            nameForSearch: null,
            employeeLists: [],
            approvedList: [{ autoApprovedYn: 'N', approverTarget: {} }],
            approvedIndex: null,
            toggleYesOrNoOptions: { true: 'Y', false: 'N' },
        };
    },
    methods: {
        // -----------------------------------------------------------------------------
        loadTooltip(ele, msgFn, options) {
            // console.log({ele});
            this.__tooltipComponent__hidden__.loadBasic(ele, msgFn, options);
        },
        loadTooltipElementReady(ele) {
            if (ele == null) return; // auto-reload, so element can be null sometime.
            this.__tooltipComponent__hidden__ = ele;
        },
        // -----------------------------------------------------------------------------
        over15CharFromFullName: ValidateUtil.over15CharFromFullName,
        openSelectApprovalPopup(index) {
            const self = this;
            self.showMemberAssignment = true;
            self.approvedIndex = index;

            // init
            self.selectedDepartment = null;
            self.nameForSearch = null;
            // self.getEmployeeListBySearch();
        },
        addApproveStep() {
            const self = this;
            if (self.approvedList.length == 5) {
                return;
            }
            self.approvedList.push({ autoApprovedYn: 'N', approverTarget: {} });
            self.openSelectApprovalPopup(self.approvedList.length - 1);
        },
        getDepartmentOptions() {
            const self = this;
            const url = self.$api('uri', 'get-department');
            const json_query = JSON.stringify({
                showRootNode: null,
            });
            self.$axios.get(url, { params: { json_query } }).then((res) => {
                self.departmentsList = res.data.data.list.map((dep) => ({
                    text: dep.departmentName,
                    value: dep.departmentSeq,
                }));

                self.departmentNameBySeq = self.departmentsList.reduce(
                    (list, department) => ({
                        ...list,
                        [department.value]: department.text,
                    }),
                    {}
                );
            });
        },
        getEmployeeOptions() {
            const self = this;

            const url = self.$api('uri', 'get-employee-all');
            self.$axios
                .get(url)
                .then((res) => {
                    self.employeeOptions = res.data.data.list.map((emp) => ({ text: emp.employeeName, value: emp.employeeSeq }));
                })
                .catch((err) => {
                    console.error('err : ', err);
                });
        },
        getEmployeeListBySearch() {
            const self = this;
            const url = self.$api('uri', 'get-employee-exclude-status-resigned');

            const searchedValues = self.$refs.employeeSearchBar.getSearchedValues();

            let json_query = {
                limit: 10,
                offset: 0,
                departmentSeq: self.selectedDepartment,
                // employeeStatus : null,
                employeeSeqList: searchedValues,
                jobTitleSeq: null,
            };
            json_query = JSON.stringify(json_query);
            self.$axios.get(url, { params: { json_query } }).then((res) => {
                self.employeeLists = res.data.data.list;
                self.employeeLists.forEach((employee) => {
                    employee.departmentName = self.departmentNameBySeq[employee.departmentSeq];
                });
            });
        },
        deleteProcessStep(processIndex) {
            const self = this;
            if (self.approvedList.length == 1) {
                delete self.approvedList[0].error;
                self.approvedList[0].approverTarget = {};
                return;
            }
            self.approvedList.splice(processIndex, 1);
        },
        selectApprover(approveType, employeeSeq, employeeName) {
            const self = this;
            self.approvedList[self.approvedIndex].approverTarget = { approveType, employeeSeq, employeeName };
            self.showMemberAssignment = false;
        },
        checkValidation() {
            const self = this;
            let isValid = true;

            self.approvedList.forEach((process, index) => {
                process.approvalStageOrder = index;
                delete process.error;

                if (!process.approverTarget.approveType) {
                    process.error = true;
                    isValid = false;
                }
            });
            return isValid;
        },
        getApprovalProcessForAxios() {
            const self = this;
            const approvalProcess = self.approvedList.map((process) => {
                const {
                    approvalStageOrder,
                    autoApprovedYn,
                    approverTarget: { approveType, employeeSeq },
                } = process;
                // delete approverTarget.employeeName;
                return { autoApprovedYn, approvalStageOrder, approveType, employeeSeq };
            });
            return approvalProcess;
        },
        getApprovalListFromDb(processItem) {
            const self = this;
            if (processItem.length < 1) return [{ autoApprovedYn: true, approverTarget: {} }];

            const approvalList = processItem.map((process) => {
                const { approvalStageOrder, autoApprovedYn, approveType, employeeSeq, employeeName } = process;
                return { autoApprovedYn, approvalStageOrder, approverTarget: { approveType, employeeSeq, employeeName } };
            });
            self.approvedList = approvalList.sort((a, b) => a.approvalStageOrder - b.approvalStageOrder);
        },
        resetApprovalProcess() {
            const self = this;
            self.approvedList = [{ autoApprovedYn: 'N', approverTarget: {} }];
            self.approvedIndex = null;
        },
    },
};
</script>
<style scoped>
.blu {
    background: #4361ee;
}
.blu:hover {
    background: #334cba;
}
.blu-text {
    color: #4361ee;
}
</style>
